<template>
  <v-card class="order-info">
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="4" md="2">
          <span>N° Ordine</span>
        </v-col>
        <v-col cols="4" md="2">
          <span>Stato</span>
        </v-col>
        <v-col cols="4" md="2">Data Ordine</v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="align-center">
        <!-- N° ordine -->
        <v-col cols="4" md="2">
          <span class="order-number">#{{ order.orderId }}</span>
        </v-col>
        <!-- stato -->
        <v-col cols="4" md="2" class="pr-md-3">
          <OrderStatusBadge v-bind:order="order" />
        </v-col>
        <!-- fattura -->
        <v-col cols="4" md="2">
          {{
            $dayjs(order.addDate, "YYYY-MM-DDThh:mm:ss A").format("D MMMM YYYY")
          }}
          <!-- {{ order.orderInfos.need_invoice === "true" ? "Si" : "No" }} -->
        </v-col>
        <!-- buttons -->
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-md-end buttons-container justify-start flex-wrap"
        >
          <OrderQrCode
            v-if="order.orderInfos.PIN_CODE"
            :codeValue="order.orderInfos.PIN_CODE"
            :width="40"
            :height="40"
          />

          <div class="d-flex flex-column">
            <v-tooltip top v-if="!order.isEditable">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="main-button reorder-button mb-1 mr-1 px-5"
                  @click="addAllToCart(order.orderId)"
                >
                  ORDINA DI NUOVO
                  <v-icon>$cart</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi tutto al carrello</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!contactUs">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="main-button reorder-button elevation-0 mb-1 mr-1"
                  v-bind="attrs"
                  v-on="on"
                  v-if="
                    !(order.orderStatusId <= 3) && order.orderStatusId !== 11
                  "
                >
                  <OrderContactForm :order="order" />
                </v-btn>
              </template>
              <span>Contattaci</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip bottom v-if="order.orderStatusId == 8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="secondary-icon-button mb-1 mr-1"
                  target="_blank"
                  :href="receiptUrl"
                  @click.prevent="
                    handleReceiptDownload(receiptUrl, order.orderId)
                  "
                >
                  <v-icon>$invoice</v-icon>
                </v-btn>
              </template>
              <span>Scarica fattura o scontrino</span>
            </v-tooltip>
            <v-tooltip bottom v-if="order.isEditable">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  depressed
                  v-on="on"
                  icon
                  class="secondary-icon-button mb-1 mr-1"
                  @click="editOrder(order.orderId)"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica ordine</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                order.orderStatusId != 11 &&
                  !(order.orderStatusId == 1 && order.paymentTypeId == 1)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  class="secondary-icon-button mb-1 mr-1"
                  @click="printOrder"
                >
                  <v-icon>$print</v-icon>
                </v-btn>
              </template>
              <span>Stampa ordine</span>
            </v-tooltip>
            <v-tooltip bottom v-if="order.isPayable">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  class="secondary-icon-button mb-1 mr-1"
                  @click="showPayment"
                >
                  <v-icon>$creditcard</v-icon>
                </v-btn>
              </template>
              <span>Ritenta pagamento</span>
            </v-tooltip>
            <v-tooltip bottom v-if="order.isDeletable">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  class="secondary-icon-button mb-1 mr-1"
                  @click="deleteOrder(order.orderId)"
                >
                  <v-icon>$bin</v-icon>
                </v-btn>
              </template>
              <span>Annulla ordine</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="4" sm="2" md="2"
              ><v-img
                :src="
                  '/img_layout/order/' +
                    order.shippingAddress.deliveryServiceId +
                    '.svg'
                "
                height="40"
                width="40"
            /></v-col>
            <v-col cols="8" sm="10" md="10">
              <!-- <strong>SERVIZIO SELEZIONATO</strong> -->
              <strong>{{
                $t(
                  "order.detailInfo.deliveryServiceType." +
                    order.shippingAddress.deliveryServiceId
                )
              }}</strong>
              <br />
              <span v-if="order.shippingAddress.addressType != 'home'">{{
                order.shippingAddress.addressName
              }}</span
              ><span v-if="order.shippingAddress.addressType == 'home'">
                {{ order.shippingAddress.address1 }}
                {{ order.shippingAddress.addressNumber }}
                {{ order.shippingAddress.postalCode }}
                {{ order.shippingAddress.city }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="2" md="2"
              ><v-img src="/img_layout/calendar.svg" height="40" width="40"
            /></v-col>
            <v-col cols="8" sm="10" md="10">
              <!-- <strong>SERVIZIO SELEZIONATO</strong> -->
              <strong
                >GIORNO E ORARIO DI
                {{
                  order.shippingAddress.addressType == "home"
                    ? "CONSEGNA"
                    : "RITIRO"
                }}</strong
              >
              <br />
              <span class="timeslot-info-col"
                >{{ $dayjs(order.timeslot.date).format("dddd D MMMM") }} |
                {{ formattedTime() }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="
              order.shippingAddress.zone.zoneGroupId == 5 &&
                order.deliveryStatusId != null &&
                order.orderStatusId != 10
            "
          >
            <v-col cols="4" sm="2" md="2"
              ><v-img
                contain
                src="/img_layout/shopopop.png"
                height="40"
                width="40"
            /></v-col>
            <v-col cols="8" sm="10" md="10">
              <!-- <strong>SERVIZIO SELEZIONATO</strong> -->
              <strong>TRACKING SHOPOPOP </strong>
              <br />
              <span class="timeslot-info-col">{{ deliveryStatusDescr }} </span>
            </v-col>
          </v-row>
          <!-- <v-row v-if="order.deliveryAddress.addressNote">
            <v-col
              cols="4"
              sm="2"
              md="2"
              class="text-left text-md-center pl-4 pl-sm-3"
              ><v-icon large color="primary lighten-1">$info</v-icon>
            </v-col>
            <v-col cols="8" sm="10" md="10">
              <strong>NOTE PER LA CONSEGNA </strong>
              <br />
              <span class="timeslot-info-col"
                >{{ order.deliveryAddress.addressNote }}
              </span>
            </v-col>
          </v-row> -->
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <h3>Riepilogo ordine</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">Tipo pagamento</v-col>
            <v-col cols="6" class="justify-end">
              <strong>{{
                $t("orders.paymentType." + order.paymentTypeId)
              }}</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">Totale ordine</v-col>
            <v-col cols="6">
              <strong>
                {{ $n(netTotal, "currency") }}
              </strong>
              <!-- <strong v-if="order.orderStatusId >= 8">{{
                $n(order.deliveredGrossTotal, "currency")
              }}</strong>
              <strong v-if="order.orderStatusId < 6">{{
                $n(order.grossTotal, "currency")
              }}</strong>
              <strong
                v-if="order.orderStatusId == 6 || order.orderStatusId == 7"
              ></strong> -->
            </v-col>
          </v-row>
          <v-row v-if="order.totalPriceVariable > 0 && !showDelivered">
            <v-col cols="6">Di cui a peso</v-col>
            <v-col cols="6">
              <strong>{{ $n(order.totalPriceVariable, "currency") }}</strong>
            </v-col>
          </v-row>

          <v-row v-if="discount != 0">
            <v-col cols="6">Sconti:</v-col>
            <v-col cols="6">
              <strong>{{ $n(discount, "currency") }}</strong>
            </v-col>
          </v-row>

          <v-row v-if="order.deliveredShippingTotal > 0">
            <v-col cols="6"><strong>Spese di spedizione</strong></v-col>
            <v-col cols="6">{{
              $n(order.deliveredShippingTotal, "currency")
            }}</v-col>
          </v-row>
          <v-row v-if="order.deliveredDeliveryFee > 0">
            <v-col cols="6">{{
              $t("cart.checkoutSummary.deliveryCharge")
            }}</v-col>
            <v-col cols="6"
              ><strong
                >{{ $n(order.deliveredDeliveryFee, "currency") }}
              </strong></v-col
            >
          </v-row>
          <v-row v-if="order.packageTotal > 0">
            <v-col cols="6"><strong>Costo sacchetti</strong></v-col>
            <v-col cols="6"
              ><strong>{{
                $n(order.deliveredPackageTotal, "currency")
              }}</strong></v-col
            >
          </v-row>
          <template v-if="!showDelivered">
            <v-row
              v-for="orderPackage in order.orderPackages"
              :key="orderPackage.orderPackageId"
            >
              <v-col cols="6">
                {{ orderPackage.package.descr }}
                <br />({{ orderPackage.quantity }}&nbsp;x&nbsp;{{
                  $n(orderPackage.package.cost, "currency")
                }})
              </v-col>
              <v-col cols="6">{{
                $n(orderPackage.grossTotal, "currency")
              }}</v-col>
            </v-row>
          </template>
          <v-row
            v-for="gift in order.giftCertificates"
            :key="gift.giftCertificateId"
          >
            <v-col cols="6">
              <span
                class="summary-textx px-2 white--text text-uppercase primary rounded-pill"
                >{{ gift.name }}</span
              >
            </v-col>
            <v-col cols="6">-{{ $n(gift.total, "currency") }}</v-col>
          </v-row>
          <v-row v-if="order.giftTotal > 0">
            <v-col cols="6">
              <strong class="giftSummary">Totale sconto</strong>
            </v-col>
            <v-col cols="6"> -{{ $n(order.giftTotal, "currency") }} </v-col>
          </v-row>
          <v-row v-if="order.refundTotal > 0">
            <v-col cols="6">
              <strong>I tuoi rimborsi</strong>
            </v-col>
            <v-col cols="6"> -{{ $n(order.refundTotal, "currency") }} </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"><h5>TOTALE</h5></v-col>
            <v-col cols="6">
              <!-- <h3 v-if="order.orderStatusId >= 8">
                {{ $n(order.deliveredGrossTotal, "currency") }}
              </h3> -->
              <!-- <h3 v-if="order.orderStatusId < 6">
                {{ $n(order.grossTotal, "currency") }}
              </h3> -->
              <h3
                v-if="order.orderStatusId == 6 || order.orderStatusId == 7"
              ></h3>
              <h3 v-else>
                {{ $n(orderGrossTotal, "currency") }}
              </h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.order-info .main-button .v-btn__content {
  justify-content: space-between;
}
.giftSummary {
  color: var(--v-primary-lighten1);
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderQrCode from "./OrderQrCode";
import downloadDocumentMixin from "~/mixins/downloadDocument";
import OrderContactForm from "@/views/forms/OrderContactForm.vue";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode, OrderContactForm },
  props: {
    order: { required: true }
  },
  computed: {
    netTotal() {
      if (this.order.orderId && this.order.orderStatusId >= 8) {
        var refundTotalDel = this.order.orderAdjustmentSummary.TYPE_REFUND_TOTAL
          .totalVariation;
        var discountItemTotalDel = this.order.orderAdjustmentSummary
          .TYPE_ITEM_TOTAL.totalVariation;
        var discountNetTotalDel = this.order.orderAdjustmentSummary
          .TYPE_NET_TOTAL.totalVariation;
        return (
          this.order.grossTotal +
          refundTotalDel +
          discountItemTotalDel +
          discountNetTotalDel
        );
      }

      var netTotal = this.order.orderId
        ? this.order.netTotal
        : this.order.totalPrice;
      var discountItemTotal = this.order.orderAdjustmentSummary.TYPE_ITEM_TOTAL
        .totalVariation;
      return netTotal + discountItemTotal;
    },

    deliveryStatusDescr() {
      return global.vm.$t(
        `order.orderDeliveryDescr[${this.order.deliveryStatusId}]`
      );
    },
    discount() {
      var discountItemTotal = this.order.orderAdjustmentSummary
        .TYPE_ITEM_TOTAL_PROMO.totalVariation;
      return discountItemTotal;
      // return this.order.giftTotal + this.order.totalDiscount;
    },
    orderGrossTotal() {
      //  return this.order.giftTotal + this.order.totalDiscount;
      var refundTotal = this.order.orderAdjustmentSummary.TYPE_REFUND_TOTAL
        .totalVariation;
      return this.order.grossTotal + refundTotal;
    },
    contactUs() {
      return (
          (this.order.orderStatusId <= 3) && this.order.orderStatusId === 11
      );
    },
    saved() {
      return (
        this.order.orderAdjustmentSummary.TYPE_ITEM_TOTAL.totalVariation +
        this.order.orderAdjustmentSummary.TYPE_NET_TOTAL.totalVariation +
        this.order.orderAdjustmentSummary.TYPE_SHIPPING_TOTAL.totalVariation
      );
      // return this.order.giftTotal + this.order.totalDiscount;
    },
    receiptUrl() {
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=5&type=pdf&order_id=${orderId}`;
    },
    showDelivered() {
      if (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 8 ||
        this.order.orderStatusId == 18
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      window.print();
    },
    showPayment() {
      this.$emit("showPayment");
    },
    formattedTime() {
      return `${this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")} - ${this.$dayjs(this.order.timeslot.endTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")}`;
    }
  }
};
</script>
