<template>
  <div class="order-detail d-flex flex-column scroll-container">
    <v-container fluid class="d-flex flex-column scroll-container" v-if="order">
      <v-row>
        <v-col class="hidden-sm-and-down MenuNavigator" cols="0" md="3">
          <MenuNavigator class="menu-navigator" />
        </v-col>
        <v-col cols="12" md="9">
          <ProfileTitle :category="category" />
          <OrderDetailInfo
            class="mb-4"
            :order="order"
            @addAllToCart="addAllToCart"
            @deleteOrder="deleteOrder"
            @editOrder="editOrder"
            @showPayment="showPayment = !showPayment"
          />
          <PaymentTypeList
            v-if="showPayment"
            :orderId="order.orderId"
            :options="{
              showRememberCard: false,
              showCardLogos: true,
              showPaymentMethodImage: true,
              mainBackgroundColor: 'transparent',
              confirm: { color: 'secondary' },
              back: {
                class: 'back-btn'
              }
              // titleStyle: 'grey--text text--darken-2 text-body-1 mb-2'
            }"
          />

          <!-- <OrderInfoSummary :order="order" :delivered="delivered" /> -->

          <OrderDetailTable
            :items="itemList"
            :delivered="delivered"
            :order="order"
          ></OrderDetailTable>

          <v-row class="actions-buttons footer-btn justify-center">
            <v-btn
              outlined
              color="primary"
              class="bg-white"
              x-large
              depressed
              @click.prevent.stop="historyBack"
            >
              TORNA AI MIEI ORDINI
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable.vue";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo.vue";
// import OrderInfoSummary from "@/components/orders/OrderInfoSummary.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";
import ProfileTitle from "@/components/profile/ProfileTitle.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";
import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";
import get from "lodash/get";

import { mapState, mapActions } from "vuex";

export default {
  name: "Order",
  data() {
    return {
      review: {
        rating: 3,
        comment: "",
        active: 1,
        title: "",
        noteId: null,
        productId: -1
      },
      order: null,
      itemList: [],
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"]
    };
  },
  mixins: [categoryMixins, reload],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    PaymentTypeList,
    // OrderInfoSummary,
    MenuNavigator,

    ProfileTitle
  },
  props: { ordersAllUsers: { type: Boolean, default: false } },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    },
    enableSave() {
      return !this.valid;
    },
    delivered() {
      // return false;
      //decommentare per mostrare i campi delivered
      return (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 8 ||
        this.order.orderStatusId == 18
      );
    },
    shippingAddress() {
      let shippingAddress = get(this.order, "shippingAddress");
      if (shippingAddress) {
        return this.$t(
          "navbar.address." + shippingAddress.addressTypeId + ".format",
          shippingAddress
        );
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    }
  },
  methods: {
    historyBack() {
      window.history.back();
    },
    am_pm_to_hours(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    addAllToCart({ orderId }) {
      this.addProductsFromOrder(orderId);
    },
    async deleteOrder({ orderId }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(orderId);
        AnalyticsService.refund(this.order);
        this.itemList = [];
        await this.reload(orderId);
      }
    },
    async editOrder({ orderId }) {
      await OrderService.editOrder(orderId);
      this.loadCart();
      this.$router.push({
        name: "Checkout"
      });
    },
    async reload(orderId) {
      let _this = this;
      this.order = await OrderService.getOrder(orderId, true);
      handleItems(this.order.suborders, this.itemList);

      function handleOrderItem(orderItem, type) {
        var q2 =
          (orderItem.deliveredQuantity > 0
            ? orderItem.deliveredQuantity
            : orderItem.deliveredWeight) || 0;

        orderItem.deliveredType =
          q2 == 0 &&
          _this.order.orderStatusId < 10 &&
          _this.order.orderStatusId > 13
            ? "replaced"
            : type;

        orderItem.deliveredItem = {
          product: orderItem.product,
          quantity: orderItem.deliveredQuantity,
          weight: orderItem.deliveredWeight
        };

        return orderItem;
      }

      function handleItems(suborders, itemList) {
        for (var s = 0; s < suborders.length; s++) {
          var suborder = suborders[s];
          for (var i = 0; i < suborder.suborderItems.length; i++) {
            var item = suborder.suborderItems[i];
            if (!item.orderItemReplacedId) {
              var alternative = null;
              var type = "ordered";
              for (var j = 0; j < suborder.suborderItems.length; j++) {
                var itemAlternative = suborder.suborderItems[j];
                if (
                  itemAlternative.orderItemReplacedId &&
                  item.orderItemId == itemAlternative.orderItemReplacedId
                ) {
                  alternative = itemAlternative;
                  type = "replaced";
                  break;
                }
              }
              itemList.push(handleOrderItem(item, type));
              if (alternative) {
                itemList.push(handleOrderItem(itemAlternative, "alternative"));
              }
            }
          }
        }
      }
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
    console.log(this);
  }
};
</script>
